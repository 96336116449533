import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Faq from "../sections/faq/DiabetesFaq"
import Footer from "../components/homeNav/footer/Footer"
import BotNav from "../components/bottomNav"
import PageNav from "../components/homeNav/PageNav"


const Diabetes = () => {
    return (
        <>
            <Seo
                title="Diabetes FAQs | Afyabook"
                description="Frequently asked Questions on Diabetes"
                img="https://res.cloudinary.com/tripleaim-software/image/upload/v1679314001/blog/diabetesImg_smn8af.jpg"
                keywords="Diabetes, High blood sugar, Symptoms of diabetes, Diabetes treatment,Diabetes diet,Blood sugar levels,Diabetes prevention,Diabetes medications"
                siteUrl="https://www.afyabook.com/diabetesFaq"
                canonical="https://www.afyabook.com/diabetesFaq"
            ></Seo>
            <PageNav />
            <HeaderContainer>
                <HeroPortfolio>
                    <HeroHeader>Diabetes FAQs</HeroHeader>
                </HeroPortfolio>
            </HeaderContainer>
            <script type="application/ld+json">
                      {`{
                        "@context": "https://schema.org/",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                              "@type": "Question",
                              "name": "What is diabetes and what causes it?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Diabetes is a condition where the body cannot properly regulate blood sugar levels. It is caused by a combination of genetic and lifestyle factors."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What are the different types of diabetes?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The main types of diabetes are type 1, type 2, and gestational diabetes."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What are the symptoms of diabetes?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Symptoms of diabetes include increased thirst, frequent urination, blurred vision, fatigue, and slow healing of cuts and wounds."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How is diabetes diagnosed?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Diabetes is diagnosed through blood tests that measure fasting blood sugar levels or HbA1c levels."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is the best diet for people with diabetes?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A healthy, balanced diet that is low in sugar and refined carbohydrates and high in fiber and lean protein is recommended for people with diabetes."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How often should I check my blood sugar levels?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Blood sugar levels should be checked regularly as recommended by your healthcare provider."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is the HbA1c test and what does it measure?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The HbA1c test measures the average blood sugar levels over the past two to three months."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How can I manage my diabetes and prevent complications?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Managing diabetes involves maintaining a healthy diet, regular exercise, monitoring blood sugar levels, and taking medications as prescribed."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Can diabetes be cured?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "There is currently no cure for diabetes, but it can be managed effectively with lifestyle changes and medication."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What medications are available to treat diabetes?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "There are many medications available to treat diabetes, including insulin, oral medications, and injectable medications."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Can diabetes be inherited?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, a family history of diabetes can increase the risk of developing the condition."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Is it safe for people with diabetes to exercise?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, exercise is beneficial for people with diabetes and can help improve blood sugar control."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How does stress affect diabetes?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Stress can increase blood sugar levels and make it harder to manage diabetes."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is diabetic ketoacidosis and how can it be prevented?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Diabetic ketoacidosis is a serious complication of diabetes that can be prevented by monitoring blood sugar levels and seeking medical attention if they become too high."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How can I manage my diabetes during pregnancy?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Diabetes during pregnancy can be managed through a healthy diet, regular exercise, and medication as prescribed by a healthcare provider."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Can people with diabetes still enjoy sweets and desserts?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, people with diabetes can enjoy sweets and desserts in moderation as part of a healthy diet."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is insulin resistance and how is it related to diabetes?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Insulin resistance is a condition where the body does not respond properly to insulin, which can lead to high blood sugar levels and eventually diabetes."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What are the long-term complications of diabetes?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Long-term complications of diabetes can include nerve damage, kidney damage, eye damage, and cardiovascular disease."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How can I maintain a healthy weight with diabetes?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Maintaining a healthy weight with diabetes involves a combination of healthy eating habits and regular exercise."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How often should I see my healthcare provider if I have diabetes?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The frequency of visits to a healthcare provider for people with diabetes may vary, but typically they are recommended every three to six months."
                              }
                            }
                          ]
                      }`}
                    </script>
            <Faq />
            <BotNav />
            <Footer />
        </>
    )
}

export default Diabetes

const HeaderContainer = styled.div`
    margin-top: 70px;
    width:100%;
`
const HeroPortfolio = styled.div`
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 50vh;
    padding: 0 1rem;
    
`
const HeroHeader = styled.h1`
    font-size: clamp(1.5rem,6vw, 3rem);
`

