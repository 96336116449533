import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "What is diabetes and what causes it?",
    body: "Diabetes is a condition where the body cannot properly regulate blood sugar levels. It is caused by a combination of genetic and lifestyle factors.",
  },
  {
    id: 1,
    title: "What are the different types of diabetes?",
    body: "The main types of diabetes are type 1, type 2, and gestational diabetes.",
  },
  {
    id: 3,
    title: "What are the symptoms of diabetes?",
    body: "Symptoms of diabetes include increased thirst, frequent urination, blurred vision, fatigue, and slow healing of cuts and wounds.",
  },
  {
    id: 4,
    title: "How is diabetes diagnosed?",
    body: "Diabetes is diagnosed through blood tests that measure fasting blood sugar levels or HbA1c levels.",
  },
  {
    id: 5,
    title: "What is the best diet for people with diabetes?",
    body: "A healthy, balanced diet that is low in sugar and refined carbohydrates and high in fiber and lean protein is recommended for people with diabetes.",
  },
  {
    id: 6,
    title: "How often should I check my blood sugar levels?",
    body: "Blood sugar levels should be checked regularly as recommended by your healthcare provider.",
  },
  {
    id: 7,
    title: "What is the HbA1c test and what does it measure?",
    body: "The HbA1c test measures the average blood sugar levels over the past two to three months.",
  },
  {
    id: 8,
    title:"How can I manage my diabetes and prevent complications?",
    body: "Managing diabetes involves maintaining a healthy diet, regular exercise, monitoring blood sugar levels, and taking medications as prescribed.",
  },
  {
    id: 9,
    title:"Can diabetes be cured?",
    body: "There is currently no cure for diabetes, but it can be managed effectively with lifestyle changes and medication.",
  },
  {
    id: 10,
    title: "What medications are available to treat diabetes?",
    body: "There are many medications available to treat diabetes, including insulin, oral medications, and injectable medications.",
  },
  {
    id: 11,
    title:"Can diabetes be inherited?",
    body: "Yes, a family history of diabetes can increase the risk of developing the condition.",
  },
  {
    id: 12,
    title:"Is it safe for people with diabetes to exercise?",
    body: "Yes, exercise is beneficial for people with diabetes and can help improve blood sugar control.",
  },
  {
    id: 13,
    title:"How does stress affect diabetes?",
    body: "Stress can increase blood sugar levels and make it harder to manage diabetes.",
  },
  {
    id: 14,
    title:"What is diabetic ketoacidosis and how can it be prevented?",
    body: "Diabetic ketoacidosis is a serious complication of diabetes that can be prevented by monitoring blood sugar levels and seeking medical attention if they become too high.",
  },
  {
    id: 15,
    title:"How can I manage my diabetes during pregnancy?",
    body: "Diabetes during pregnancy can be managed through a healthy diet, regular exercise, and medication as prescribed by a healthcare provider.",
  },
  {
    id: 16,
    title:"Can people with diabetes still enjoy sweets and desserts?",
    body: "Yes, people with diabetes can enjoy sweets and desserts in moderation as part of a healthy diet.",
  },
  {
    id: 17,
    title:"What is insulin resistance and how is it related to diabetes?",
    body: "Insulin resistance is a condition where the body does not respond properly to insulin, which can lead to high blood sugar levels and eventually diabetes.",
  },
  {
    id: 18,
    title:"What are the long-term complications of diabetes?",
    body: "Long-term complications of diabetes can include nerve damage, kidney damage, eye damage, and cardiovascular disease.",
  },
  {
    id: 19,
    title:"How can I maintain a healthy weight with diabetes?",
    body: "Maintaining a healthy weight with diabetes involves a combination of healthy eating habits and regular exercise.",
  },
  {
    id: 20,
    title:"How often should I see my healthcare provider if I have diabetes?",
    body: "The frequency of visits to a healthcare provider for people with diabetes may vary, but typically they are recommended every three to six months.",
  },
]
export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions our clients ask about Diabetes</h1>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
